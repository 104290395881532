$totalwidth: 94vw;
$margin_top: 30vh;

.clear {
  clear: both;
}

// > 768
@media screen and (min-width: 768px) {

  .ant-modal {
    // width: 100%;
    // height: 100%;
    // position: fixed;
    // top: 0;
    // z-index: 99999;
    // background: rgba(0, 0, 0, 0.5);
    // background-color: red;

    .popup-is-open {
      margin: auto;
      // margin-top: $margin_top;
      width: 400px;
      text-align: center;
      padding: 30px 0 20px;
      background-color: #fff;
      border-radius: 2px;

      img {
        height: 52px;
        margin-bottom: 20px;
      }

      .popup-is-open-text {
        font-size: 14px;
        font-weight: 400;
        color: rgba(37, 33, 82, 1);
        line-height: 24px;
      }
    }
  }


  .wrap-mob,
  .mobile-only,
  .disn {
    display: none;
  }

  .App {
    // width: 710px;
    margin: 0 auto;
    // background-color: red;
  }

  .other-tokens-wrap {
    width: 780px;
    margin: 0 auto;
  }

  .other-tokens {
    height: 60px;
    width: 348px;
    background-color: #fff;
    position: relative;
    border: 1px solid rgba(229, 230, 242, 1);
    border-radius: 4px;

    .token-balance-left {
      position: absolute;
      width: 100%;
      // background-color: green;
      top: -26px;
      font-size: 16px;
      color: #252152;

      .my-balance {
        // width: 200px;
        // background-color: green;
        float: right;
        text-align: right;
      }

      .my-balance-title {
        // width: 200px;
        // background-color: green;
        float: right;
        text-align: right;
        font-size: 14px;
        font-weight: 400;
        color: rgba(89, 88, 105, 1);
        padding-top: 2px;
        margin-right: 3px;
      }
    }

    .other-tokens-left {
      float: left;
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 1px;
        height: 32px;
        position: absolute;
        right: 0;
        top: 14px;
        background-color: #CDCEDC;
        // background-color: red;
      }

      button {
        width: 117px;
        height: 58px;
        background-color: #fff;
        text-align: left;
        padding-left: 8px;
        position: relative;
        cursor: pointer;
        border-radius: 9px;

        .token-logo {
          width: 28px;
          display: block;
          float: left;
        }

        .token-title {
          font-size: 16px;
          font-weight: 500;
          color: rgba(75, 82, 125, 1);
          // background-color: green;
          display: block;
          float: left;
          padding-top: 7px;
          padding-left: 6px;
        }

        .token-tips {
          position: absolute;
          right: 8px;
          top: 26px;
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-top: 6px solid #595869;
        }

        .token-tips-re {
          position: absolute;
          right: 10px;
          top: 20px;
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-bottom: 6px solid #595869;
        }

        .more-tokens {
          position: absolute;
          top: 64px;
          width: 348px;
          background-color: #fff;
          overflow: auto;
          z-index: 99999;
          left: 0;
          border: 1px solid rgba(229, 230, 242, 1);
          box-shadow: 0px 8px 30px 0px rgba(7, 2, 55, 0.08);
          border-radius: 4px;
          max-height: 325px;
          // background-color: #f00;

          .more-tokens-token {
            position: relative;
            height: 60px;
            border-bottom: 1px solid #EAECF3;

            .token-logo {
              width: 28px;
              position: absolute;
              left: 14px;
              top: 15px;
            }

            .token-title {
              font-size: 16px;
              font-weight: 500;
              color: rgba(75, 82, 125, 1);
              position: absolute;
              left: 44px;
              top: 15px;

              i {
                color: #929DB7;
                padding-left: 10px;
              }
            }

            .token-isselected {
              width: 18px;
              position: absolute;
              right: 20px;
              top: 22px;
            }
          }
        }
      }

      .token-islocked {
        width: 18px;
        position: absolute;
        left: 140px;
        top: 28px;
      }
    }

    .other-tokens-right {
      float: right;
      width: 229px;
      border-radius: 6px;
      overflow: hidden;
      position: relative;

      input {
        height: 58px;
        width: 100%;
        font-size: 16px;
        padding-left: 10px;
        background-color: #fff;
      }

      .other-tokens-right-max {
        color: #29BB92;
        position: absolute;
        right: 10px;
        top: 22px;
        font-size: 16px;
        cursor: pointer;
      }
    }

    .other-tokens-rate {
      position: absolute;
      left: 0;
      top: 66px;
      font-size: 12px;
      font-weight: 400;
      color: #252152;
      font-style: italic;
    }

    .other-tokens-rate-p {
      position: absolute;
      left: 0;
      top: 82px;
      font-size: 12px;
      font-weight: 400;
      color: #252152;
      font-style: italic;
    }

    .text-right {
      text-align: right;
      // background-color: red;
      width: 100%;

      .Liquidity-tips {
        font-style: italic;

        .Liquidity-tit {
          margin-right: 5px;
        }

        .Liquidity-num {
          color: #FF3939;
          cursor: pointer;
          font-size: 14px;
        }
      }
    }
  }

  .exchange {
    display: inline-block;
    padding-left: 31px;
    padding-top: 18px;
    position: relative;

    img.exc {
      width: 20px;
      cursor: pointer;
    }

    img.exc_mob {
      display: none;
    }

    .show-tips {
      position: absolute;
      top: 104px;
      // background: red;
      width: 300px;
      left: -112px;
      text-align: center;

      .show-tips-img {
        width: 20px;
        margin-bottom: -3px;
        margin-right: 5px;
      }

      .show-tips-text {
        font-size: 16px;
        font-weight: 400;
        color: rgba(246, 151, 38, 1);
      }
    }
  }

  .float-left {
    float: left;
  }

  .float-right {
    float: right;
  }

  .btn-wrap {
    width: 308px;
    height: 58px;
    line-height: 58px;
    text-align: center;
    margin: 0 auto;
    background-color: #29BB92;
    margin-top: 72px;
    font-size: 18px;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
    margin-bottom: 20px;
    box-shadow: 0px 8px 19px 0px rgba(41, 187, 146, 0.4);
    border-radius: 30px;
  }

  .btn-wrap-disable {
    width: 308px;
    height: 58px;
    line-height: 58px;
    text-align: center;
    margin: 0 auto;
    background-color: #cdcedc;
    margin-top: 72px;
    font-size: 18px;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
    margin-bottom: 20px;
    box-shadow: 0px 8px 19px 0px rgba(205, 206, 220, 0.4);
    border-radius: 30px;
  }

  .token-balance {
    // background-color: red;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(37, 33, 82, 1);



    .token-balance-right {
      float: right;
      width: 316px;

      .my-balance {
        // width: 200px;
        // background-color: green;
        float: right;
        text-align: right;
      }

      .my-balance-title {
        // width: 200px;
        // background-color: green;
        float: right;
        text-align: right;
        font-size: 12px;
        font-weight: 400;
        color: rgba(89, 88, 105, 1);
        padding-top: 3px;
        margin-right: 3px;
      }
    }
  }


  // foot
  .foot {
    border-top: 1px solid rgba(229, 230, 242, 0.7);
    padding-top: 26px;
    width: 874px;
    // background-color: red;
    margin: 50px auto 0;
    padding-bottom: 4px;

    .foot-item {
      float: left;
      width: 200px;
      // background-color: palegoldenrod;

      .foot-item-title {
        height: 30px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(109, 107, 133, 1);
      }

      .foot-item-content {
        // width: 380px;
        height: 22px;
        font-size: 13px;
        font-weight: 400;
        color: rgba(134, 133, 147, 1);
        // background-color: red;

        a {
          // display: inline-block;
          text-decoration: none;
          transition: 0.3s;
          // height: 40px;
          font-size: 13px;
          font-weight: 400;
          color: rgba(134, 133, 147, 1);
          // color: red;

          &:hover {
            color:#BA59FF
          }

          position: relative;
        }

        .weixin-img {
          display: none;
          position: absolute;
          width: 80px !important;
          left: -25px;
          bottom: 40px;
        }

        .weixin-img-wrap {
          position: relative;

          img {
            width: 23px;
          }
        }

        .weixin-img-wrap:hover {
          .weixin-img {
            display: block;
          }
        }
      }

      .footer-right-fixed:hover {
        .fixed2 {
          display: block;
        }
      }

      .footer-right-fixed {
        padding-top: 12px;
        position: relative;
        cursor: pointer;
        // background-color: red;
        width: 80px;
        font-size: 14px;


        .fixed1 {
          float: left;
        }

        .fixed2 {
          display: none;
          position: absolute;
          bottom: 5px;
          background: #FFF;
          width: 90px;
          box-shadow: 0px 0px 35px 0px rgba(94, 85, 126, 0.15);
          /* border-radius: 6px; */
          left: -20px;

          ul {
            margin-bottom: 0;
            padding: 0;
            li {
              text-align: center;
              line-height: 36px;
              font-size: 12px;

              &:hover {
                background: #F0EEFF;
                cursor: pointer;
              }
            }

            li:nth-child(1) {
              border-bottom: 1px solid #DDDDE8;
            }
          }
        }

        .fixed-img {
          float: left;
          margin-left: 10px;

          img {
            width: 10px;
          }
        }

        ul,
        li {
          list-style: none;
        }
      }

    }

    .padding-left20 {
      padding-left: 20px;
    }

    .foot-item:nth-child(2n) {
      width: 309px;
      // background-color: red;
    }

    .icom-a {
      a {
        margin-right: 6px;

        img {
          width: 23px;
        }
      }
    }
  }
}


// 
@media screen and (max-width: 767px) {
  .header {
    display: none !important;
  }

  .ant-modal {
    // background-color: red;
    // width: 100%;
    // height: 100%;
    // position: fixed;
    // z-index: 99999;
    // background: rgba(0, 0, 0, 0.5);

    .popup-is-open {
      margin: auto;
      // margin-top: $margin_top;
      width: 305px;
      text-align: center;
      padding: 30px 0 20px;
      background-color: #fff;
      border-radius: 2px;

      img {
        height: 48px;
        margin-bottom: 20px;
      }

      .popup-is-open-text {
        font-size: 12px;
        font-weight: 400;
        color: rgba(37, 33, 82, 1);
        line-height: 24px;
      }
    }
  }

  .mobile-only {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
    background: #fff;

    .only-kong {
      height: 30px;
    }

    h1 {
      width: 100%;
      margin-top: 20px;
      font-size: 16px;
      color: rgba(7, 2, 55, 1);
      padding-left: 10px;
      padding-right: 15px;

      span {
        float: right;

        img {
          width: 14px;
        }
      }
    }

    .only1px {
      height: 1px;
      overflow: hidden;
      // transition: 0.3s;
    }

    .meun1 {
      // transition: 0.3s;
      height: auto;
      // background-color: red;
      width: 300px;

      // height:1px;
      // overflow: hidden;
      .m-item {
        // background-color: red;

        a {
          text-decoration: none;
          display: block;
          font-size: 14px;
          color: rgba(7, 2, 55, 1);
          // line-height: 34px;
          padding-left: 20px;
          padding-right: 20px;
          margin-top: 15px;
          .title1{
            font-weight: 400;
            color: #070237;
          }
        }

        .details {
          display: block;
          padding-left: 20px;
          font-size: 13px;
          font-weight: 400;
          color: rgba(139, 142, 165, 1);
          margin-top: 5px;
        }
      }

    }
  }

  .disn {
    display: none;
  }

  .wrap-mob {
    // background-color: red;
    width: 94%;
    margin: 0 auto 10px;

    .only-left {
      float: left;
      padding: 10px 0 0 0;

      img {
        width: 80px;
      }
    }

    .only-right {
      float: right;
      padding: 16px 5px 10px 10px;
      // background-color: red;

      img {
        width: 16px;
        // margin-right: -10px;
      }
    }
  }

  .App {
    width: 100%;
    // margin: 0 auto;
    // background-color: red;
  }

  .other-tokens {
    height: 54px;
    width: 94%;
    // width: 355px;
    background-color: #fff;
    position: relative;
    border: 1px solid rgba(229, 230, 242, 1);
    border-radius: 5px;
    // background-color: #f00;
    margin: 0 auto;
    margin-left: 3%;
    display: flex;

    .token-balance-left {
      position: absolute;
      width: 100%;
      // background-color: green;
      top: -20px;
      font-size: 14px;
      color: #252152;

      .my-balance {
        // width: 200px;
        // background-color: green;
        float: right;
        text-align: right;
      }

      .my-balance-title {
        // width: 200px;
        // background-color: green;
        float: right;
        text-align: right;
        font-size: 12px;
        font-weight: 400;
        color: rgba(89, 88, 105, 1);
        padding-top: 2px;
        margin-right: 3px;
      }
    }


    .other-tokens-left {
      float: left;
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 1px;
        height: 32px;
        position: absolute;
        right: 0;
        top: 11px;
        background-color: #CDCEDC;
      }

      button {
        width: 107px;
        height: 52px;
        background-color: #fff;
        text-align: left;
        padding-left: 6px;
        position: relative;
        cursor: pointer;
        border-radius: 9px;

        .token-logo {
          width: 26px;
          display: block;
          float: left;
        }

        .token-title {
          font-size: 14px;
          font-weight: 500;
          color: rgba(75, 82, 125, 1);
          // background-color: green;
          display: block;
          float: left;
          padding-top: 7px;
          padding-left: 5px;
        }

        .token-tips {
          position: absolute;
          right: 8px;
          top: 25px;
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-top: 6px solid #929DB7;
        }

        .token-tips-re {
          position: absolute;
          right: 8px;
          top: 20px;
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-bottom: 6px solid #929DB7;
        }

        .more-tokens {
          position: absolute;
          top: 60px;
          width: 316px;
          width: $totalwidth;
          // width: 100%;
          background-color: #fff;
          overflow: auto;
          z-index: 9999;
          left: 0;
          border: 1px solid rgba(229, 230, 242, 1);
          box-shadow: 0px 8px 30px 0px rgba(7, 2, 55, 0.08);
          border-radius: 4px;
          max-height: 217px;
          // background-color: red;

          .more-tokens-token {
            position: relative;
            height: 54px;
            border-bottom: 1px solid #EAECF3;

            .token-logo {
              width: 26px;
              position: absolute;
              left: 10px;
              top: 15px;
            }

            .token-title {
              font-size: 14px;
              font-weight: 500;
              color: rgba(75, 82, 125, 1);
              position: absolute;
              left: 40px;
              top: 15px;

              i {
                color: #929DB7;
                padding-left: 10px;
              }
            }

            .token-isselected {
              width: 18px;
              position: absolute;
              right: 20px;
              top: 22px;
            }
          }
        }
      }

      .token-islocked {
        width: 18px;
        position: absolute;
        left: 140px;
        top: 28px;
      }
    }

    .other-tokens-right {
      float: right;
      // width: 207px;
      flex: 1;
      border-radius: 6px;
      overflow: hidden;
      position: relative;

      input {
        height: 52px;
        width: 100%;
        font-size: 16px;
        padding-left: 10px;
        background-color: #fff;
      }

      .other-tokens-right-max {
        color: #29BB92;
        position: absolute;
        right: 10px;
        top: 19px;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .other-tokens-rate {
      position: absolute;
      left: 0;
      top: 60px;
      font-size: 11px;
      font-weight: 400;
      color: #252152;
      font-style: italic;
    }

    .other-tokens-rate-p {
      position: absolute;
      left: 0;
      top: 76px;
      font-size: 11px;
      font-weight: 400;
      color: #252152;
      font-style: italic;
    }

    .text-right {
      // text-align: right;
      // background-color: red;
      width: 100%;

      .Liquidity-tips {
        font-style: italic;

        .Liquidity-tit {
          margin-right: 5px;
        }

        .Liquidity-num {
          color: #FF3939;
          cursor: pointer;
          font-size: 14px;
        }
      }
    }

  }

  .exchange {
    clear: both;
    display: block;
    position: relative;
    // background-color: red;
    padding-top: 40px;
    margin-bottom: 30px;
    text-align: center;

    img.exc {
      display: none;
    }

    img.exc_mob {
      width: 20px;
      cursor: pointer;
    }

    .show-tips {
      position: absolute;
      top: 173px;
      // background: red;
      width: 100%;
      text-align: center;

      .show-tips-img {
        width: 16px;
        margin-bottom: -1px;
        margin-right: 5px;
      }

      .show-tips-text {
        font-size: 14px;
        font-weight: 400;
        color: rgba(246, 151, 38, 1);
      }
    }
  }

  .float-left {
    float: left;
  }

  .float-right {
    float: right;
    margin-right: 3%;
    margin-left: 0;
  }

  .btn-wrap {
    width: 280px;
    height: 52px;
    line-height: 52px;
    text-align: center;
    margin: 0 auto;
    background-color: #29BB92;
    margin-top: 58px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
    margin-bottom: 28px;
    box-shadow: 0px 8px 19px 0px rgba(41, 187, 146, 0.4);
    border-radius: 25px;
  }

  .btn-wrap-disable {
    width: 280px;
    height: 52px;
    line-height: 52px;
    text-align: center;
    margin: 0 auto;
    background-color: #cdcedc;
    margin-top: 58px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
    margin-bottom: 28px;
    box-shadow: 0px 8px 19px 0px rgba(205, 206, 220, 0.4);
    border-radius: 25px;
  }

  .token-balance {
    // background-color: red;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(37, 33, 82, 1);



    .token-balance-right {
      float: right;
      width: 316px;

      .my-balance {
        width: 200px;
        // background-color: green;
        float: right;
        text-align: right;
      }
    }
  }


  // foot
  .foot {
    // padding-top: 50px;
    // width: 1200px;
    // background-color: red;
    width: 94%;
    margin: 35px auto 0;
    padding-bottom: 4px;
    border-top: 1px solid rgba(229, 230, 242, 0.7);
    // border-top: 1px solid rgba(229, 0, 0, 1);

    .foot-item {
      // float: left;
      // width: 270px;
      // background-color: palegoldenrod;
      width: 100%;
      float: left;

      .foot-item-title {
        margin-top: 30px;
        font-size: 14px;
        color: #6d6b85;
        height: 30px;
      }

      .foot-item-content {
        font-size: 12px;
        color: #868593;
        height: 26px;

        a {
          text-decoration: none;
          font-size: 12px;
          color: #868593;
          transition: 0.3s;
          position: relative;

          &:hover {
            color: #29BB92;
          }

          img {
            width: 20px;
          }
        }

        .weixin-img {
          display: none;
          position: absolute;
          width: 80px !important;
          left: 0px;
          bottom: 24px;
        }

        .weixin-img-wrap {
          position: relative;

          img {
            width: 20px;
          }
        }

        .weixin-img-wrap:hover {
          .weixin-img {
            display: block;
          }
        }
      }

      .footer-right-fixed {
        display: none;
      }

    }

    .foot-item:nth-child(3n) {
      width: 100%;
      // background-color: red;
    }

    .icom-a {
      a {
        margin-right: 15px;
      }
    }
  }
}