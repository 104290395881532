.warp{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1{
        font-size: 30px;
    }
    div{
        line-height: 30px;
        margin-bottom: 20px;
    }
}